import React, { lazy } from "react";
import { Redirect } from "react-router-dom";
import LoginLayout from "src/layouts/LoginLayout";
import DashboardLayout from "src/layouts/DashboardLayout";

export const routes = [
  {
    exact: true,

    path: "/",
    layout: LoginLayout,
    component: lazy(() => import("src/views/pages/Auth/Login")),
  },
  {
    exact: true,

    path: "/login",
    layout: LoginLayout,
    component: lazy(() => import("src/views/pages/Auth/Login")),
  },
  {
    exact: true,

    path: "/forget",
    layout: LoginLayout,
    component: lazy(() => import("src/views/pages/Auth/Forget")),
  },
  {
    exact: true,

    path: "/reset-password",
    layout: LoginLayout,
    component: lazy(() => import("src/views/pages/Auth/ResetPassword")),
  },
  {
    exact: true,
    path: "/verify-otp",
    layout: LoginLayout,
    component: lazy(() => import("src/views/pages/Auth/Verifyotp")),
  },
  {
    exact: true,
    path: "/change-password",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Auth/ChangePassword")),
  },
  {
    exact: true,
    guard: true,
    path: "/dashboard",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/DashdoardHome")),
  },
 
  {
    exact: true,
    guard: true,
    path: "/graph/skill-subskill",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/DashBoard/SkillSubskill")),
  },
  {
    exact: true,
    guard: true,
    path: "/graph/state-city",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/DashBoard/CityState")),
  },
  {
    exact: true,
    guard: true,
    path: "/graph/perday-user",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/DashBoard/Perdayuser")),
  },
  
  {
    exact: true,
    guard: true,
    path: "/graph/month-year",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/DashBoard/MonthYear")),
  },
  {
    exact: true,
    guard: true,
    path: "/graph/active-subscription",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/DashBoard/ActiveSubscription")),
  },
  
  {
    exact: true,
    guard: true,
    path: "/transactions-history",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Transection")),
  },
  {
    exact: true,
    guard: true,
    path: "/add-university",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/University/AddUniversity")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/add-price",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/price/index")),
  },
  {
    exact: true,
    guard: true,
    path: "/view-price",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/price/index")),
  },
  {
    exact: true,
    guard: true,
    path: "/membership-list",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/MembershipPlan/Membership")),
  },
  {
    exact: true,

    path: "/signup",
    layout: LoginLayout,
    component: lazy(() => import("src/views/pages/Auth/Signup")),
  },
  {
    exact: true,
    guard: true,
    path: "/subscription",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Subscription")),
  },

  {
    exact: true,
    guard: true,
    path: "/setting",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/Setting/BotSetting")
    ),
  },
  {
    exact: true,
    path: "/my-wallet",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Wallet/Mywallet")),
  },
  {
    exact: true,
    path: "/profile",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Profile")),
  },
  {
    exact: true,
    path: "/edit-profile",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/EditProfile")),
  },
  {
    exact: true,
    path: "/notification",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Static/Notification")),
  },
  {
    exact: true,
    path: "/privacy-policy",
    layout: LoginLayout,
    component: lazy(() => import("src/views/pages/Static/Privacy")),
  },
  {
    exact: true,
    path: "/FAQ",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Static/FaqSection")),
  },
  {
    exact: true,
    path: "/term-and-condition",
    layout: LoginLayout,
    component: lazy(() => import("src/views/pages/Static/Terms")),
  },
  {
    exact: true,
    path: "/add-enterprises",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/EnterPrises/index")
    ),
  },
  {
    exact: true,
    path: "/view-enterprises",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/EnterPrises/AddEnterPrisesList")
    ),
  },
  {
    exact: true,
    path: "/degree-list",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/degree/index")),
  },
  {
    exact: true,
    guard: true,
    path: "/add-degree",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/degree/index")),
  },
  {
    exact: true,
    guard: true,
    path: "/wallets-transaction",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Wallets/Index")),
  },
  {
    exact: true,
    guard: true,
    path: "/nonlinked-wallet",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Wallets/UnlinkedWallets")),
  },
  {
    exact: true,
    guard: true,
    path: "/wallets-balance",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Wallets/WalletBalance")),
  },
  {
    exact: true,
    guard: true,
    path: "/wallets-list",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Wallets/Walletstable")),
  },
  {
    exact: true,
    guard: true,
    path: "/user-wallet",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Wallets/UserWalletDetails")),
  },
  {
    exact: true,
    guard: true,
    path: "/view-university",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/University/ViewUniversity")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/edit-university",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/University/EditUniversity")
    ),
  },
  {
    exact: true,
    path: "/domain-list",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/domain/index")),
  },
  {
    exact: true,
    path: "/skills-list",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/skills/index")),
  },
  {
    exact: true,
    path: "/skills-lists",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Skill/Index.js")),
  },
  {
    exact: true,
    path: "/subskills-list",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/SubSkill/Index.js")),
  },
  {
    exact: true,
    path: "/referral-list",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/referral/index")),
  },
  {
    exact: true,
    path: "/referral-userlist",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/referral/UserList.js")),
  },
  {
    exact: true,
    guard: true,
    path: "/add-domain",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/domain/index")),
  },
  {
    exact: true,
    path: "/subject-list",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/subject/index")),
  },
  {
    exact: true,
    path: "/add-subject",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/subject/index")),
  },
  // {
  //   exact: true,
  //   path: "/course-list",
  //   guard: true,
  //   layout: DashboardLayout,
  //   component: lazy(() => import("src/views/pages/Dashboard/course/index")),
  // },
  {
    exact: true,
    path: "/marketplace",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/PaidCourses/index")),
  },
  {
    exact: true,
    path: "/drop",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/DropCourses/index")),
  },
  {
    exact: true,
    path: "/drop-list",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/drop/Drop")),
  },
  {
    exact: true,
    path: "/skill-drops-list",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/SkillDropList/index")),
  },
  {
    exact: true,
    path: "/marketplace-list",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/MarketplaceList/index")),
  },
  {
    exact: true,
    path: "/user-lists",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/MarketplaceList/MarketplaceTable.js")),
  },
  {
    exact: true,
    path: "/user-skilldrops-lists",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/SkillDropList/SkillListTable")),
  },
  {
    exact: true,
    path: "/faculity-list",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/faculity/index")),
  },
  {
    exact: true,
    path: "/add-faculity",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/faculity/index")),
  },
  {
    exact: true,
    path: "/query-list",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/querylist/QueryList")
    ),
  },
  {
    exact: true,
    path: "/query-reply",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/querylist/Index")
    ),
  },
  {
    exact: true,
    path: "/user-list",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/userlist/UserList")
    ),
  },
  {
    exact: true,
    path: "/create-badges",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/automation/Badge")),
  },
  {
    exact: true,
    path: "/badges-list",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/automation/BadgeMain")),
  },

  {
    exact: true,
    path: "/course-userList",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/CourseUserDetail/CourseUser")),
  },
  {
    exact: true,
    path: "/member-ship",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/MembershipPlan/Index.js")),
  },
  {
    exact: true,
    path: "/notifications",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/announcement")),
  },
  {
    exact: true,
    path: "/subscribe-user",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/SubscribeUser/index")),
  },
  {
    exact: true,
    path: "/blog",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Blogs/Blogs")),
  },
  {
    exact: true,
    path: "/educator-request",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/EducatorRequest/CourseRequest")),
  },
  {
    exact: true,
    path: "/job-career",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/JobCarrier/index.js")),
  },
  {
    exact: true,
    path: "/static",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Static/Index.js")),
  },
  {
    exact: true,
    path: "/view-notification",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/announcement/UserListTable.js")),
  },
  {
    exact: true,
    path: "/view-user",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/userlist/UserTabs")
    ),
  },
  {
    exact: true,
    path: "/view-subscriber",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/MembershipPlan/View")
    ),
  },
  {
    exact: true,
    path: "/transaction-list",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/transaction/TransactionMangement")
    ),
  },
  {
    exact: true,
    path: "/coe-request",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/CoeRequest/CoeRequest")
    ),
  },
  {
    exact: true,
    path: "/gyan-coins",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/gyancoins/GyanCoins")
    ),
  },
  // {
  //   exact: true,
  //   path: "/automation",
  //   layout: DashboardLayout,
  //   component: lazy(() =>
  //     import("src/views/pages/Dashboard/automation/Automation")
  //   ),
  // },
  {
    exact: true,
    path: "/university-list",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/University/UniversityList")
    ),
  },
  {
    exact: true,
    path: "/transaction",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/component/Transaction")),
  },
  {
    exact: true,
    path: "/attendance",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/component/Attendence")),
  },
  {
    exact: true,
    path: "/challenges",
    layout: DashboardLayout,
    component: lazy(() => import("src/component/Challenges")),
  },
  {
    exact: true,
    path: "/employer",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/Employer/index")),
  },
  // {
  //   exact: true,
  //   path: "/resume",
  //   guard: true,
  //   layout: DashboardLayout,
  //   component: lazy(() => import("src/Resume/index")),
  // },
  {
    exact: true,
    path: "/particular-faculity-list",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/particularfaculity/ParticularFaculity")),
  },
  
  {
    exact: true,
    path: "/course-module",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/course module/index")
    ),
  },

  {
    exact: true,
    path: "/instructor-list",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/instructor/Instructor")
    ),
  },
  {
    exact: true,
    path: "/coe-partners",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/coepartners/Index.js")
    ),
  },
  {
    exact: true,
    path: "/add-technology",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/technology/index")),
  },
  {
    exact: true,
    path: "/admin",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/component/Admin")),
  },
  {
    exact: true,
    path: "/subadmin-list",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/subadmin/SubAdmin")
    ),
  },
  {
    exact: true,
    path: "/tier-plan-subscribers",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/MembershipPlan/Membership.js")
    ),
  },
  {
    exact: true,
    path: "/view-subadmin",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/subadmin/ViewParticularSubAdmin")
    ),
  },
  {
    exact: true,
    path: "/certificate-list",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/certificaterequest/CertificateRquest")
    ),
  },
  {
    exact: true,
    path: "/course-category",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/courseCategory/index")
    ),
  },
  {
    exact: true,
    path: "/course-template",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/courseCategory/CourseTemplate/Index.jsx")
    ),
  },
  {
    exact: true,
    path: "/course-template/create-couseTemp",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/courseCategory/CourseTemplate/CreateCourseTem.jsx")
    ),
  },
  {
    exact: true,
    path: "/course-template/view-couseTemp",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/courseCategory/CourseTemplate/ViewCourseTemp")
    ),
  },
  {
    exact: true,
    path: "/course-category/subcategory",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/courseCategory/courseSubCategory/index")
    ),
  },

  {
    exact: true,
    path: "/404",
    component: lazy(() => import("src/views/errors/NotFound")),
  },
  {
    component: () => <Redirect to="/404" />,
  },
];
